<template>
	<div class="PageBox">
		<van-nav-bar
				fixed
				:border="false"
				:title="$t('withdrawlist[0]')"
				left-arrow
				@click-left="$router.go(-1)"
		>
		</van-nav-bar>
		<div class="bg">
			<div class="panel">
				<van-list v-model="isLoad" :finished="isFinished" @load="onLoad">
					<div class="mytable">
						<van-cell class="FundItem" :border="false"
								  v-for="(item, index) in listData" :key="item.id">
							<div style="display: flex">
								<div style="width: 60%">{{item.dan}}</div>
								<div style="color: var(--font_subtitle)">{{item.adddate}}</div>
							</div>
							<div style="display: flex">
								<div style="width: 60%;color:var(--button_bg);font-weight: bold">{{parseFloat(item.money).toFixed(2)}}</div>
								<div>
									<span v-if="item.status==1" style="color:red">{{$t('task.msg[0]')}}</span>
									<span v-if="item.status==2" style="color:#0FCB81">{{$t('login.codes[3]')}}</span>
									<span v-if="item.status==3" style="color:yellow">{{$t('task.tabs[1]')}}</span>
								</div>
							</div>
              <div style="width: 100%;height: 60px">
                {{item.remarks}}
              </div>
						</van-cell>
					</div>
				</van-list>
			</div>
		</div>

	</div>

</template>

<script>
	export default {
		name: 'Wallet',
		components: {

		},
		props: ['walletType','id'],
		data() {
			return {
				active:0,
				isLoad: false,//显示正在加载
				isFinished: false,//控制是否禁止触发上拉触底的事件
				pageNo:1,
				pagesize:20,
				listData:[],
				robotUserName:localStorage['robotUserName']||''
			}
		},
		created() {
			this.active = 0;
			this.listData = [];
			this.getListData("init");
		},
		mounted() {

		},
		methods: {
      clickLeft(){
        debugger
      },
			tabChanged(index){
				this.active = index;
				this.getListData("init");
			},
			cancelTask(id) {
				this.$Model.SubmitTask({
					order_id: id,
					status: 2
				}, (data) => {
					if (data.code == 1) {
						this.getListData("init");
					}
				});
			},
			//触底触发，页面初始化触发
			onLoad() {
				// 异步更新数据
				this.getListData("load");
			},
			//获取用户历史记录
			getListData(type) {
				this.isLoad = true;
				this.isRefresh = false;
				if (type == "load") {
					this.pageNo += 1;
				} else {
					this.pageNo = 1;
					this.isFinished = false;
				}
				let json={
					page_no: this.pageNo,
				}
				this.pages++
				this.$Model.GetDrawRecord(json,
						(data) => {
					this.isLoad = false;
					if (data.code == 1) {
						if (type == "load") {
							this.listData = this.listData.concat(data.info);
						} else if (data.info){
							this.listData = data.info;
						}
						if (this.pageNo == data.data_total_page) {
							this.isFinished = true;
						} else {
							this.isFinished = false;
						}
					} else {
						this.listData = [];
						this.isFinished = true;
					}
				})
			},
			onClickRight(){
				this.$router.push("/user/withdrawalCenter");
			},
		},
	}
</script>

<style scoped>
	/* .van-nav-bar--fixed,.Site .van-nav-bar{
	background-color: #0f25fe;
} */
	.PageBox {
		overflow: auto;

		padding-top: 20px;
	}

	.PageBox .bgc {
		width: 100%;
		height: 46px;
		position: fixed;
		top: 0;
		z-index: 200;
	}

	.PageBox .panel{
		width: 95%;
		margin-left: 10px;
		margin-top: 40px;
	}

	.FundItem {
		margin-top: 10px;
		border-radius: 10px;
		line-height: 30px;
    border: 1px solid var(--border_color2);
	}

	.topTitle {
		position: absolute;
		max-width: 70%;
		white-space: nowrap;
		font-size: 18px;
		color: #fff;
		background-color: transparent;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		z-index: 9999;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		display: block;
	}

	.PageBox>>>.mytable {
		margin: 0 auto;
		margin-top: 16px;
	}

  .van-cell {
    background-color: var(--panel_color);
    padding-left: 10px;
  }

	.PageBox>>>table {
		width: 100%;
		border: none;
		/* border-spacing: 1px; */
	}

	.PageBox>>>th:nth-child(1) {
		background-color: #b3b5fc;
		font-weight: 600;
		font-size: 13px;
		/* padding: 6px 0px; */
		border: none;
		height: 33px;
		width: 26%;
	}

	.PageBox>>>th:nth-child(2) {
		background-color: #b3b5fc;
		font-weight: 600;
		font-size: 13px;
		/* padding: 6px 0px; */
		border: none;
		height: 33px;
		width: 24px;
	}

	.PageBox>>>th:nth-child(3) {
		background-color: #b3b5fc;
		font-weight: 600;
		font-size: 13px;
		/* padding: 6px 0px; */
		border: none;
		height: 33px;
		width: 24%;
	}

	.PageBox>>>th:nth-child(4) {
		background-color: #b3b5fc;
		font-weight: 600;
		font-size: 13px;
		/* padding: 6px 0px; */
		border: none;
		height: 33px;
		width: 26%;
	}

	.PageBox>>>table tr td {
		font-size: 11px;
		color: #626262;
		padding: 8px 0px;
		text-align: center;
		border: none;
	}

	table tbody tr:nth-child(odd) {
		background: #eff0f2;
	}

	table tbody tr:nth-child(even) {
		background: #e1e1f9;
	}

	/* .PageBox >>> .mytable {
	  background: #eff0f2;
	  width: 90%;
	  margin: 0 auto;
	  margin-top: 20px;
	}
	.PageBox >>> table {
	  width: 100%;
	  margin: 0 5% 0 5%;
	  padding-bottom: 20px;
	  border-spacing: 0;
	}
	.PageBox >>> th {
	  background: #f5f6ff;
	  font-weight: 700;
	  font-size: 18px;
	  position: relative;
	}
	.PageBox >>> th:nth-of-type(5) {
	  border: none;
	}
	.PageBox >>> .tabHead div {
	  height: 12px;
	  width: 1px;
	  background: #b9babd;
	  position: absolute;
	  left: 100%;
	  top: 10px;
	  z-index: 10;
	}
	.PageBox >>> table tr td {
	  height: 41px;
	  color: #626262;
	  font-size: 15.78px
	} */
</style>
